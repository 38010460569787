import React from "react"
import PageSectionHeader from "./PageSectionHeader"
import IconBox from "../molecules/IconBox"

const WhyChooseSynkliSection = ({data}) => {
  return (
    <div className="bg-[#0A1E46]">
      <div className="synkli-section--horizontal-space py-16 sm:py-20 mt-20 md:mt-24 mb-10">
        <PageSectionHeader
          subTitle={data.subTitle}
          subTitleEC={`text-white border-white ${data.subTitleEC}`}
          title={data.title}
          titleEC={`text-white !max-w-[680px] ${data.titleEC}`}
          description={data.description}
          descriptionEC={`text-white max-w-[1000px] mx-auto ${data.descriptionEC}`}
        />

        <div className="flex flex-col md:flex-row items-stretch gap-5 mt-12 max-w-[1200px] mx-auto">
          {data.iconBoxes.map((box, index) => (
            <IconBox
              key={index}
              componentEC={'!py-10 ' + box.componentEC}
              IconBoxLink={box.link}
              image={box.image}
              imageEC={box.imageEC}
              hoveredImage={box.hoveredImage}
              hoveredImageEC={box.hoveredImageEC}
              title={box.title}
              titleEC={box.titleEC}
              description={box.description}
              descriptionEC={box.descriptionEC}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default WhyChooseSynkliSection
