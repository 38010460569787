import React from "react"
import Button from "../atoms/Button"
import ParsePropasHtml from "./ParsePropasHtml"
import ImageRenderer from "../atoms/ImageRenderer"

const InnerPageHeroSection = ({
  titleWrapEC,
  title,
  titleEC,
  description,
  descriptionEC,
  btns,
  image,
  widget
}) => {
  return (
    <div className="synkli-section--horizontal-space synkli-section--vertical-space">
      <div className={`text-center mx-auto ${titleWrapEC}`}>
        <h1
          className={
            "mx-auto capitalize text-center inline font-bold text-4xl lg:text-7xl md:text-6xl !leading-[1.4em] font-poppins text-[#0A1E46] " +
              titleEC || ""
          }
        >
          <ParsePropasHtml text={title} />
        </h1>

        <span className="relative top-[22px] ml-3 hidden lg:inline">
          <ImageRenderer img={"star-icon-for-title"} />
        </span>
      </div>
      <p
        className={
          "main-page-description mt-3 sm:mt-4 pt-3 sm:pt-4 mx-auto text-center text-lg md:text-2xl !leading-[1.5em] font-poppins text-[#0A1E46] " +
            descriptionEC || ""
        }
      >
        {description}
      </p>
      <div className="flex gap-4 flex-wrap justify-center items-center mt-3 sm:mt-4 pt-4 sm:pt-6">
        {btns &&
          btns.map((btn, index) => (
            <Button
              key={"hero_section_" + index}
              to={btn?.to || "/"}
              href={btn?.href || ""}
              text={btn?.text || ""}
              ec={btn?.ec || ""}
            />
          ))}
      </div>
      {image && (
        <div className="text-center mt-14 max-w-[1430px] mx-auto px-6">
          <ImageRenderer img={image.path} alt={image.alt} />
        </div>
      )}
      {widget && (
        <div className="text-center mt-14 max-w-[1230px] mx-auto px-6">
          {widget}
        </div>
      )}
    </div>
  )
}

export default InnerPageHeroSection
