import React from "react"
import PageBgSectionHeader from "./PageBgSectionHeader"
import ImageRenderer from "../atoms/ImageRenderer"
import { Link } from "gatsby"
import { appLinks } from "../../Content/Global"

const MobileAppLinksSection = ({ title, description }) => {
  return (
    <div className="synkli-section--horizontal-space">
      <div className="mt-20 md:mt-24 mb-10 flex flex-col sm:gap-10 md:flex-row px-8 sm:px-16 py-10 md:py-14 justify-between  items-center bg-[#F3F0FF] rounded-[30px]">
        <div className="max-w-[480px] w-[100%] flex flex-col justify-center">
          <PageBgSectionHeader
            title={title}
            titleEC={
              "text-[#2A2A2A] !lg:text-[44px] font-[500] !leading-[1.2em]"
            }
            description={description}
            descriptionEC={
              "text-[#2A2A2A] lg:text-[18px] leading-[1.4em] font-[400] !mb-0"
            }
          />
        </div>
        <div className="max-w-[400px] md:max-w-[250px] lg:max-w-[500px] mt-8 md:mt-3 flex justify-center gap-5">
          <Link
            to={appLinks.googlePlay}
            href={appLinks.googlePlay}
            className="max-w-[130px] md:max-w-[230px] w-full"
            target="_blank"
          >
            <ImageRenderer img={"google-play"} alt="Google Play" />
          </Link>

          <Link
            to={appLinks.applePay}
            href={appLinks.applePay}
            target="_blank"
            className="max-w-[115px] md:max-w-[200px] w-full"
          >
            <ImageRenderer img={"apple-pay"} alt="Apple Pay" />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default MobileAppLinksSection
