// src/components/IconBox.js

import React from "react"
import ImageRenderer from "../atoms/ImageRenderer"

const IconBox = ({
  componentEC,
  IconBoxLink,
  image,
  imageEC,
  hoveredImage,
  hoveredImageEC,
  title,
  titleEC,
  description,
  descriptionEC,
}) => {
  return (
    <div
      className={`group hover:bg-[#B695F8] duration-300 flex flex-col gap-5 bg-white shadow-md border border-[#001F1F08] rounded-3xl w-full py-6 px-6 ${componentEC} ${
        IconBoxLink ? "cursor-pointer" : ""
      }`}
      onClick={() => {
        if (IconBoxLink) {
          window.location.href = IconBoxLink
        }
      }}
    >
      <div className="relative">
        <div
          className={`max-w-[70px] ${imageEC}  ${
            hoveredImage ? "group-hover:opacity-[0]" : ""
          }`}
        >
          <ImageRenderer img={image.path} alt={image.alt} />
        </div>
        {hoveredImage && (
          <div
            className={`transition duration-1000 opacity-[0] absolute top-0 left-0 group-hover:opacity-[1] ${hoveredImageEC}`}
          >
            <ImageRenderer img={hoveredImage.path} alt={hoveredImage.alt} />
          </div>
        )}
      </div>
      {title && (
        <h2
          className={`text-[22px] md:text-[24px] leading-[1.2em] font-[600] text-[#000000] group-hover:text-white duration-300 ${titleEC}`}
        >
          {title}
        </h2>
      )}

      {description && (
        <p
          className={`text-[16px] md:text-[18px] leading-[1.4em] text-[#555555] group-hover:text-white duration-300 ${descriptionEC}`}
        >
          {description}
        </p>
      )}
    </div>
  )
}

export default IconBox
