export const outlookAddinPage = {
  heroSection: {
    bgImage: "outlook-addin-page--hero-section-bg",
    featureIconImage: {
      path: `outlook-addin-page--outlook-icon`,
      alt: `outlook-addin-page--outlook-icon`,
    },
    title: `<span class="text-secondary">Boost</span> Efficiency with Synkli Add-In for <span class="text-secondary">Outlook</span>`,
    description: `Enhance your accounting workflow by seamlessly managing tasks, leads, and client checkups from your Outlook app. Streamline your processes and increase productivity with real-time synchronisation to the Synkli portal.`,
    btns: [
      {
        to: `https://partners.synkli.com.au/signup/`,
        href: `https://partners.synkli.com.au/signup/`,
        text: "Sign up Now",
        ec: "primary-btn",
      },
      {
        to: "https://appsource.microsoft.com/en-us/product/web-apps/WA200007317?ocid=GTMRewards_WhatsNewBlog_WA200007317_08152024",
        href: "https://appsource.microsoft.com/en-us/product/web-apps/WA200007317?ocid=GTMRewards_WhatsNewBlog_WA200007317_08152024",
        text: "Add integration",
        ec: "primary-transparent-btn",
      },
    ],
  },
  CTASection: {
    componentEC: "flex-col md:flex-row-reverse",
    CTATitle: `Unlock the full potential of your accounting workflow with the Synkli add-in`,
    CTADescription: `Seamlessly integrated with the Synkli portal, this powerful tool transforms your Outlook application into a productivity powerhouse, specifically designed for accountants and their teams.`,
    CTAList:{
        title: `How to Get Started`,
        titleEC: ``,
        listItems: [
            `<span class="font-[600]">Sign Up as a Synkli Partner:</span> Visit <a href="https://partners.synkli.com.au/signup" target="_blank">partners.synkli.com.au/signup</a> to create your account.`,
            `<span class="font-[600]">Purchase a Subscription:</span> Choose the subscription plan that best fits your needs.`,
            `<span class="font-[600]">Log In and Go:</span> Use your Synkli portal credentials to log in to the add-in and start enhancing your productivity.`
        ]
    },
    CTAListEC: `!mb-0`,
    CTAImage: {
      path: `outlook-addin-page--cta-section-image`,
      alt: `outlook-addin-page--cta-section-image`,
    },
    CTAHoveredImage: {
      path: `outlook-addin-page--cta-section-image-hovered`,
      alt: `outlook-addin-page--cta-section-image-hovered`,
    },
  },
  featuresSection: {
    title: `Key Features-Everything you need to get ahead`,
    description: ``,
    features: [
      {
        componentEC: "",
        title: "Task Management:",
        description:
          "Synkli's task management integrates seamlessly with Outlook, allowing you to create, assign, and track tasks effortlessly. Manage client deadlines, project milestones, and internal to-dos all in one place. Synkli syncs tasks with its portal for real-time updates, ensuring you and your team stay on top of every activity. With features like due dates, priority levels, and progress tracking, keep your accounting tasks organised and on schedule.",
        image: {
          path: "outlook-addin-page--feature-task-management",
          alt: "outlook-addin-page--feature-task-management",
        },
        hoveredImage: {
          path: `outlook-addin-page--feature-task-management-hovered`,
          alt: `outlook-addin-page--feature-task-management-hovered`,
        },
      },
      {
        componentEC: "flex-col md:flex-row-reverse",
        title: "Lead Management:",
        description:
          "Synkli’s lead management integrates with Outlook to help you generate, track, and nurture leads effortlessly. Create new leads directly from emails to ensure no opportunity is missed. This feature keeps detailed records of all interactions and follow-ups, syncing automatically with the Synkli portal. By centralising your lead management, Synkli ensures your sales pipeline is always current, helping you convert leads into clients and drive business growth efficiently.",
        image: {
          path: "outlook-addin-page--feature-lead-management",
          alt: "outlook-addin-page--feature-lead-management",
        },
        hoveredImage: {
          path: `outlook-addin-page--feature-lead-management-hovered`,
          alt: `outlook-addin-page--feature-lead-management-hovered`,
        },
      },
      {
        componentEC: "",
        title: "Existing Client Lookup:",
        description:
          "Synkli’s client lookup feature offers instant access to your client database from Outlook. Review client history, past interactions, and ongoing projects without leaving your email client. By integrating client details into your email workflow, you can respond with personalised and timely information, boosting customer satisfaction and strengthening relationships. This feature ensures all client data is easily accessible, simplifying client inquiries and enhancing service quality.",
        image: {
          path: "outlook-addin-page--feature-client-checkup",
          alt: "outlook-addin-page--feature-client-checkup",
        },
        hoveredImage: {
          path: `outlook-addin-page--feature-client-checkup-hovered`,
          alt: `outlook-addin-page--feature-client-checkup-hovered`,
        },
      },
    ],
  },
  synkliAdvantageSection: {
    title: `Experience the Synkli Advantage`,
    description: `Transform how you manage your accounting tasks and leads with the Synkli add-in. Designed with accountants in mind, Synkli offers a robust solution to streamline your workflow, improve efficiency, and keep you organised.`,
    image: {
        path: `outlook-addin-page--synkli-advantage-section-image`,
        alt: `outlook-addin-page--synkli-advantage-section-image`
    },
    description2: `Get started today and see how Synkli can revolutionise your accounting processes. Sign up, subscribe, and integrate – it's that simple.`
  }
}
