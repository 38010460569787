// Testimonial Section
export const testimonials = {
  testimonialsFirstColumn: [
    {
      image: {
        path: "client-testimonial-img-info-1",
        alt: "client-testimonial-img-info-1",
      },
      name: "Kylie Roberts",
      address: "CFO",
      testimonial:
        "Tracking expenses and income used to be a headache, but Synkli's intuitive interface has simplified the process. I can easily monitor our cash flow, generate detailed reports, and communicate with other team members. This feature has given us valuable insights into our financial health, leading to better financial planning.",
    },
    {
      image: {
        path: "client-testimonial-img-info-4",
        alt: "client-testimonial-img-info-4",
      },
      name: "Adam Brownlie",
      address: "Sales Manager",
      testimonial:
        "Manual mileage tracking was a hassle, but with Synkli's mileage tracker, it's a breeze. The automatic tracking for business and personal trips ensures accurate logs and deductions claims. This feature has saved us time and improved our accuracy in reporting travel expenses.",
    },
    {
      image: {
        path: "client-testimonial-img-info-5",
        alt: "client-testimonial-img-info-5",
      },
      name: "Steve Howard",
      address: "Operations Director",
      testimonial:
        "Having all our team members in one place on Synkli has greatly benefited us. We can assign roles, track progress, and ensure everyone is on the same page. This centralised platform has improved collaboration and productivity, making team management more efficient.",
    },
  ],
  testimonialsSecondColumn: [
    {
      image: {
        path: "client-testimonial-img-info-2",
        alt: "client-testimonial-img-info-2",
      },
      name: "Diana Lopez",
      address: "Business Owner",
      testimonial:
        "Since switching to Synkli, managing our financial data has never been easier. We used to struggle with multiple systems and files, but now everything is in one secure place. The ability to access and manage our data from anywhere has streamlined our accounting process and significantly boosted our productivity.",
    },
    {
      image: {
        path: "client-testimonial-img-info-6",
        alt: "client-testimonial-img-info-6",
      },
      name: "John Stark",
      address: "Accountant",
      testimonial:
        "Synkli's online signature feature has been a game-changer for us. Signing documents online quickly and securely saves us so much time. It's reassuring to know that our documents are legally binding and safely stored, making our document management more efficient than ever.",
    },
    {
      image: {
        path: "client-testimonial-img-info-3",
        alt: "client-testimonial-img-info-3",
      },
      name: "Liana Popek",
      address: "Project Manager",
      testimonial:
        "Efficient communication is key to our business, and Synkli's integrated chatbox has made it so much easier to keep in touch with my team and accountants. Instant messaging within the platform ensures quick responses and keeps everyone aligned, enhancing our productivity and teamwork.",
    },
  ],
}


// Mobile App Links Section
export const mobileAppLinksSection= {
  title: `Maximising your tax returns with easy deduction claims`,
  description: `Download our mobile app to ensure you have accurate record keeping and an increase in your tax refunds. With Synkli, you can explore many accounting tools and features.`
}


// Why Choose Synkli Section
export const whyChooseSynkliSection = {
  subTitle: `Productivity`,
  title: `Why Choose Synkli?`,
  description: `Accountants and clients choose Synkli to digitally manage financial data and automate workflows to upgrade accounting practice management.`,
  iconBoxes: [
    {
      image: {
        path: "star-icon-straight-for-title",
        alt: "star-icon-for-title",
      },
      hoveredImage: {
        path: "star-icon-white-for-title",
        alt: "star-icon-straight-for-title",
      },
      title: "Improved Accounting Practice Management:",
      description: `Simplify your accounting workflows and enhance accounting practice management with Synkli. Our management software is designed to modify, optimise, and automate client financial data, enhance accountants' accounting practices, and focus on offering exceptional digital service to your clients.`,
    },
    {
      image: {
        path: "star-icon-straight-for-title",
        alt: "star-icon-for-title",
      },
      hoveredImage: {
        path: "star-icon-white-for-title",
        alt: "star-icon-straight-for-title",
      },
      title: "Efficient Client Data Management:",
      description: `Synkli is a powerful data management tool designed specifically for accountants. It enables them to organise clients' financial information digitally. By leveraging automation, accountants can easily access, manage, and streamline client data, enhancing their accounting practice management.`,
    },
    {
      image: {
        path: "star-icon-straight-for-title",
        alt: "star-icon-straight-for-title",
      },
      hoveredImage: {
        path: "star-icon-white-for-title",
        alt: "star-icon-white-for-title",
      },
      title: "Client and Accountant Collaboration:",
      description: `Synkli, a client management tool, has developed easy collaboration between clients and accountants for accounting workflows. This enables accountants to collaborate with clients in real time and manage financial data, making accountants' practice management more manageable for efficient tax benefits.`,
    },
  ]
}