import React from "react"
import { outlookAddinPage } from "../../Content/FeatureInnerPages"
import InnerPageHeroSection from "../../components/common/InnerPageHeroSection"
import ImageUrlRenderer from "../../components/atoms/ImageUrlRenderer"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import ImageRenderer from "../../components/atoms/ImageRenderer"
import CTASectionWithImage from "../../components/molecules/CTASectionWithImage"
import FeaturesWithHoveredImageSection from "../../components/common/FeaturesWithHoveredImageSection"
import MobileAppLinksSection from "../../components/common/MobileAppLinksSection"
import WhyChooseSynkliSection from "../../components/common/WhyChooseSynkliSection"
import {
  mobileAppLinksSection,
  whyChooseSynkliSection,
} from "../../Content/Common"
import PageSectionHeader from "../../components/common/PageSectionHeader"

const outlookAddin = () => {
  const ImageURL = ImageUrlRenderer({
    img: outlookAddinPage.heroSection.bgImage,
  })
  return (
    <Layout>
      <Seo
        title="Efficient Client Data Management with Synkli Add-In for Outlook"
        description={`Enhance client data management with Synkli Add-In for Outlook, designed for accountants. Streamline workflows and organise client info efficiently.`}
      />
      <div
        className="bg-cover bg-center "
        style={{
          backgroundImage: `url('${ImageURL}')`,
        }}
      >
        <div className="synkli-section--horizontal-space synkli-section--vertical-space">
          <div className="text-center max-w-[110px] md:max-w-[150px] mx-auto">
            <ImageRenderer
              img={outlookAddinPage.heroSection.featureIconImage.path}
              alt={outlookAddinPage.heroSection.featureIconImage.alt}
            />
          </div>
        </div>
        <InnerPageHeroSection
          titleWrapEC={`max-w-[1080px]`}
          title={outlookAddinPage.heroSection.title}
          description={outlookAddinPage.heroSection.description}
          descriptionEC={`max-w-[1180px]`}
          btns={outlookAddinPage.heroSection.btns}
        />
      </div>
      <div className="synkli-section--horizontal-space synkli-section--vertical-space">
        <CTASectionWithImage
          componentEC={outlookAddinPage.CTASection.componentEC}
          title={outlookAddinPage.CTASection.CTATitle}
          description={outlookAddinPage.CTASection.CTADescription}
          list={outlookAddinPage.CTASection.CTAList}
          listEC={outlookAddinPage.CTASection.CTAListEC}
          image={{
            path: outlookAddinPage.CTASection.CTAImage.path,
            alt: outlookAddinPage.CTASection.CTAImage.alt,
          }}
          hoveredImage={{
            path: outlookAddinPage.CTASection.CTAHoveredImage.path,
            alt: outlookAddinPage.CTASection.CTAHoveredImage.alt,
          }}
        />
      </div>

      <FeaturesWithHoveredImageSection
        title={outlookAddinPage.featuresSection.title}
        items={outlookAddinPage.featuresSection.features}
      />

      <MobileAppLinksSection
        title={mobileAppLinksSection.title}
        description={mobileAppLinksSection.description}
      />

      <WhyChooseSynkliSection data={whyChooseSynkliSection} />

      <div className="">
        <div className="synkli-section--horizontal-space synkli-section--vertical-space">
          <PageSectionHeader
            title={outlookAddinPage.synkliAdvantageSection.title}
            titleEC={`max-w-[100%] ${outlookAddinPage.synkliAdvantageSection.titleEC}`}
            description={outlookAddinPage.synkliAdvantageSection.description}
            descriptionEC={`max-w-[1100px] mx-auto ${outlookAddinPage.synkliAdvantageSection.descriptionEC}`}
          />
          <div className="py-16">
            <ImageRenderer
              img={outlookAddinPage.synkliAdvantageSection.image.path}
              alt={outlookAddinPage.synkliAdvantageSection.image.alt}
            />
          </div>
          <p
            className={`text-center max-w-[1000px] mx-auto font-default text-lg font-poppins text-[#202020] ${outlookAddinPage.synkliAdvantageSection.descriptionEC}`}
          >
            {outlookAddinPage.synkliAdvantageSection.description2}
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default outlookAddin
